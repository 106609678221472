body {
	height: 100%;
	max-height: 100vh;
	overflow-y: hidden;
}

button {
	cursor: pointer;
}

.k-input {
	width: auto;
	display: flex;
	border: 1px solid var(--ui-2);
	box-shadow: none;
	outline: none;
	color: var(--text-color);
	background-color: var(--ui);
	border-radius: 0.25rem;
	padding: 0.15rem 0.5rem;
	resize: vertical;
	min-height: 1.65rem;
	height: 1.65rem;
	max-height: 30vh;
	-webkit-appearance: none;
	appearance: none;
	transition: var(--duration-fast-02) var(--expressive-standard);
}
.k-input:focus {
	border-color: var(--accent);
}

.flex-code {
	display: flex;
	overflow-x: scroll;
	max-width: 100%;
}

@media only screen and (max-width: 472px) {
	.portrait-special-w {
		top: 64px !important;
		width: calc(100% - 2rem);
	}
}