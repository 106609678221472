.react-flow__selection {
	background: rgba(0, 89, 220, 0.08);
	border: 1px dotted rgba(0, 89, 220, 0.8);
}
.react-flow__edge.selected .react-flow__edge-path {
	stroke: var(--ui-7);
}
.react-flow__edge.animated path {
	stroke-dasharray: 5;
	animation: dashdraw 0.5s linear infinite;
}
.react-flow__edge.updating .react-flow__edge-path {
	stroke: var(--ui-7);
}
.react-flow__edge-path {
	stroke: var(--ui-4);
	stroke-width: 1;
}
.react-flow__edge-text {
	font-size: 10px;
}
.react-flow__edge-textbg {
	fill: var(--ui);
}
.react-flow__connection-path {
	stroke: var(--ui-4);
	stroke-width: 1;
}
.react-flow__node {
	cursor: grab;
}

/* NODES */
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output {
	padding: 10px;
	border-radius: 3px;
	width: 150px;
	font-size: 12px;
	color: var(--text-color-09);
	text-align: center;
	border-width: 1px;
	border-style: solid;
}
.react-flow__node-default.selectable:hover,
.react-flow__node-input.selectable:hover,
.react-flow__node-output.selectable:hover {
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
}
.react-flow__node-input {
	background: var(--accent-10);
	border-color: var(--accent-60);
	color: var(--accent) !important;
}
body.dark .react-flow__node-input {
	background: var(--accent-100);
}
.react-flow__node-input.selected,
.react-flow__node-input.selected:hover {
	box-shadow: 0 0 0 0.5px var(--accent-60);
}
.react-flow__node-input .react-flow__handle {
	background: var(--accent-60);
}
.react-flow__node-default {
	background: var(--ui);
	border-color: var(--ui-yinyang);
}
.react-flow__node-default.selected,
.react-flow__node-default.selected:hover {
	box-shadow: 0 0 0 0.5px var(--ui-yinyang);
}
.react-flow__node-default .react-flow__handle {
	background: var(--ui-yinyang);
}
.react-flow__node-output {
	background: var(--magenta-10);
	border-color: var(--magenta-60);
	color: var(--magenta-60) !important;
}
body.dark .react-flow__node-output {
	background: var(--magenta-100);
}
.react-flow__node-output.selected,
.react-flow__node-output.selected:hover {
	box-shadow: 0 0 0 0.5px var(--magenta-50);
}
.react-flow__node-output .react-flow__handle {
	background: var(--magenta-50);
}


.react-flow__node-default.green {
	background: var(--green-10) !important;
	border-color: var(--green-60) !important;
	color: var(--green-50) !important;
}
body.dark .react-flow__node-default.green  {
	background: var(--green-100) !important;
}
.react-flow__node-default.green.selected,
.react-flow__node-default.green.selected:hover {
	box-shadow: 0 0 0 0.5px var(--green-50) !important;
}
.react-flow__node-default.green  .react-flow__handle {
	background: var(--green-50) !important;
}

.react-flow__node-default.gray {
	background: var(--gray-10) !important;
	border-color: var(--gray-60) !important;
}
body.dark .react-flow__node-default.gray  {
	background: var(--gray-100) !important;
}
.react-flow__node-default.gray.selected,
.react-flow__node-default.gray.selected:hover {
	box-shadow: 0 0 0 0.5px var(--gray-50) !important;
}
.react-flow__node-default.gray  .react-flow__handle {
	background: var(--gray-50) !important;
}


.react-flow__node-default.blue {
	background: var(--blue-10) !important;
	border-color: var(--blue-60) !important;
	color: var(--blue-60) !important;
}
body.dark .react-flow__node-default.blue  {
	background: var(--blue-100) !important;
}
.react-flow__node-default.blue.selected,
.react-flow__node-default.blue.selected:hover {
	box-shadow: 0 0 0 0.5px var(--blue-50) !important;
}
.react-flow__node-default.blue  .react-flow__handle {
	background: var(--blue-50) !important;
}


.react-flow__node-default.cyan {
	background: var(--cyan-10) !important;
	border-color: var(--cyan-40) !important;
	color: var(--cyan-60) !important;
}
body.dark .react-flow__node-default.cyan  {
	background: var(--cyan-100) !important;
}
.react-flow__node-default.cyan.selected,
.react-flow__node-default.cyan.selected:hover {
	box-shadow: 0 0 0 0.5px var(--cyan-50) !important;
}
.react-flow__node-default.cyan  .react-flow__handle {
	background: var(--cyan-50) !important;
}

.react-flow__node-default.teal {
	background: var(--teal-10) !important;
	border-color: var(--teal-40) !important;
	color: var(--teal-60) !important;
}
body.dark .react-flow__node-default.teal  {
	background: var(--teal-100) !important;
}
.react-flow__node-default.teal.selected,
.react-flow__node-default.teal.selected:hover {
	box-shadow: 0 0 0 0.5px var(--teal-50) !important;
}
.react-flow__node-default.teal  .react-flow__handle {
	background: var(--teal-50) !important;
}




.react-flow__nodesselection-rect {
	background: var(--text-color-015);
	border: 1px dotted rgba(0, 89, 220, 0.8);
}
.react-flow__handle {
	position: absolute;
	width: 7px;
	height: 7px;
	background: var(--accent);
	border: 1px solid var(--ui);
	border-radius: 100%;
}
.react-flow__handle.connectable {
	cursor: crosshair;
}
.react-flow__minimap {
	background-color: var(--ui);
}
.react-flow__controls {
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
}
.react-flow__controls-button {
	background: var(--ui-1);
	border-bottom: 1px solid var(--ui-2);
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 16px;
	height: 16px;
	cursor: pointer;
	user-select: none;
	padding: 5px;
}
.react-flow__controls-button svg {
	max-width: 12px;
	max-height: 12px;
}
.react-flow__controls-button:hover {
	background: #f4f4f4;
}


text {
	fill: var(--text-color) !important;
}